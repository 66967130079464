<template>
  <div id="app">
    <router-view />
    <!-- Put it at the end of App.vue -->
    <dialogs-wrapper wrapper-name="default" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {}
  },
  computed: {},
  components: {},
  watch: {},
  methods: {},
  created () {
    // this.$store.dispatch('initApp')
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
}
// ios 底部安全区域
.ios #app {
  padding-bottom: 30px;
}
</style>
